import React, { Component } from "react"
import anime from "animejs/lib/anime.es.js"
import { Link } from "gatsby"
//styles
import styles from "./button-animated.module.scss"

class ButtonAnimated extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transition: null,
      id: null,
    }
    // this.item = React.createRef()
  }

  componentDidMount() {
    // let item = this.item.current

    const transition = anime
      .timeline({
        easing: "easeOutCubic",
        duration: 1500,
        autoplay: false,
      })
      .add({
        targets: this.refs.item.querySelectorAll(`.${styles.text} i`),
        duration: 200,
        bottom: ["0px", "12px"],
        opacity: [1, 0],
        easing: "easeInOutCirc(1, .5)",
        delay: anime.stagger(20),
      })
      .add(
        {
          targets: this.refs.item.querySelectorAll(`.${styles.overlay} i`),
          duration: 200,
          bottom: ["-12px", "-2px"],
          opacity: [0, 1],
          easing: "easeInOutCirc(1, .5)",
          delay: anime.stagger(20),
        },
        "-=300"
      )
      .add(
        {
          targets: this.refs.item.querySelector(`.${styles.line}`),
          duration: 200,
          width: ["0%", "100%"],
          // opacity: [0, 1],
          // background: ["#000", "#fff"],
          easing: "easeInOutCirc(1, .5)",
        },
        100
      )

    this.setState({
      transition: transition,
    })
  }

  hoverIn = () => {
    if (this.state.transition.reversed) {
      this.state.transition.reverse()
      this.state.transition.play()
    } else {
      this.state.transition.play()
    }
  }
  hoverOut = () => {
    if (!this.state.transition.reversed) {
      this.state.transition.reverse()
      this.state.transition.play()
    }
  }

  render() {
    const { to, text, type, invert } = this.props
    const letters = [...text]
    return (
      <>
        {type === "external" ? (
          <a
            href={to}
            rel="nofollow noreferrer"
            alt={text}
            ref="item"
            target="_blank"
            title={text}
            className={[styles.button, "button"].join(" ")}
            onMouseEnter={this.hoverIn}
            onMouseLeave={this.hoverOut}
            {...this.props}
          >
            <span className={styles.text}>
              {letters.map((letter, i) => {
                return <i key={i}>{letter}</i>
              })}
            </span>
            <span
              className={[styles.overlay, invert ? styles.invert : ""].join(
                " "
              )}
            >
              {letters.map((letter, i) => {
                return <i key={i}>{letter}</i>
              })}
            </span>
            <span className={styles.line}></span>
          </a>
        ) : type === "dummy" ? (
          <span
            rel="nofollow noreferrer"
            ref="item"
            className={[styles.button, "button"].join(" ")}
            onMouseEnter={this.hoverIn}
            onMouseLeave={this.hoverOut}
            {...this.props}
          >
            <span className={styles.text}>
              {letters.map((letter, i) => {
                return <i key={i}>{letter}</i>
              })}
            </span>
            <span className={styles.overlay}>
              {letters.map((letter, i) => {
                return <i key={i}>{letter}</i>
              })}
            </span>
            <span className={styles.line}></span>
          </span>
        ) : type === "submit" ? (
          <button
            type="submit"
            ref="item"
            className={[styles.button, "button"].join(" ")}
            onMouseEnter={this.hoverIn}
            onMouseLeave={this.hoverOut}
            {...this.props}
          >
            <span className={styles.text}>
              {letters.map((letter, i) => {
                return <i key={i}>{letter}</i>
              })}
            </span>
            <span className={styles.overlay}>
              {letters.map((letter, i) => {
                return <i key={i}>{letter}</i>
              })}
            </span>
            <span className={styles.line}></span>
          </button>
        ) : (
          <Link
            to={to}
            rel="nofollow noreferrer"
            alt={text}
            ref="item"
            className={[styles.button, "button"].join(" ")}
            onMouseEnter={this.hoverIn}
            onMouseLeave={this.hoverOut}
            {...this.props}
          >
            <span className={styles.text}>
              {letters.map((letter, i) => {
                return <i key={i}>{letter}</i>
              })}
            </span>
            <span
              className={[styles.overlay, invert ? styles.invert : ""].join(
                " "
              )}
            >
              {letters.map((letter, i) => {
                return <i key={i}>{letter}</i>
              })}
            </span>
            <span className={styles.line}></span>
          </Link>
        )}
      </>
    )
  }
}

export default ButtonAnimated
