import React from "react"
import { graphql } from "gatsby"
import SEO from "components/SEO"
import Layout from "components/Layout"
import BlogOverview from "components/BlogOverview"
import Pagination from "components/Pagination"

// TODO: Add infinite scroll:
// https://www.wesleylhandy.net/blog/infinite-scroll-mobile-desktop-gatsby.html

const BlogIndex = props => {
  const { data, pageContext } = props
  const { postPrefix, title } = data.site.siteMetadata
  // console.log("title", title)

  const { edges: posts } = data.allWordpressPost

  return (
    <Layout location={props.location} navigation>
      <SEO
        seo={{
          title: "Alle blogs",
          description: "Onze blogs...",
        }}
      />
      <div className="wrapper">
        <BlogOverview blogs={posts} prefix={postPrefix} />
        <Pagination pageContext={pageContext} pathPrefix="/blog" />
      </div>
    </Layout>
  )
}
export default BlogIndex

export const blogQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        title
        postPrefix
      }
    }
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          author {
            name
            image {
              alt_text
              title
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          slug
          categories {
            name
            slug
          }
          slug
          id
          wordpress_id
          seo {
            title
            siteName
            description
            image {
              src {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1280, maxHeight: 853, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          date(formatString: "DD MMMM, YYYY")
          hero {
            author
            date
            image {
              title
              src {
                alt_text
                title
                source_url
                localFile {
                  childImageSharp {
                    fluid(
                      quality: 100
                      srcSetBreakpoints: [400, 1024, 1280, 1921]
                      maxWidth: 1921
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            readingTime
            title
          }
          author {
            name
            image {
              alt_text
              title
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
