import React from "react"

// components
import ButtonAnimated from "components/ButtonAnimated"

// styles
import styles from "./pagination.module.scss"

const Pagination = ({ pageContext, pathPrefix }) => {
  const { previousPagePath, nextPagePath } = pageContext

  return (
    <nav className={styles.pagination} role="navigation">
      <div className={styles.nav}>
        {previousPagePath && (
          <div className={styles.navitem}>
            <ButtonAnimated to={previousPagePath} text="Vorige" rel="prev" />
          </div>
        )}
        {nextPagePath && (
          <div className={styles.navitem}>
            <ButtonAnimated to={nextPagePath} text="Volgende" rel="next" />
          </div>
        )}
      </div>
    </nav>
  )
}

export default Pagination
