import React from "react"
import { Link } from "gatsby"

import BlogItem from "components/BlogItem"

import styles from "./blogoverview.module.scss"

const BlogOverview = props => {
  const posts = props.blogs

  return (
    <div className={styles.container}>
      {posts.map(({ node, i }) => (
        <BlogItem key={node.slug} details={node} />
      ))}
    </div>
  )
}

export default BlogOverview
