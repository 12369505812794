import React, { Component } from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import ReactHtmlParser from "react-html-parser"

import styles from "./blogitem.module.scss"

class BlogItem extends Component {
  render() {
    const { slug, author, date, hero } = this.props.details

    let featuredImage = {}

    if (hero.image.src !== null && hero.image.src.localFile) {
      featuredImage = hero.image.src.localFile.childImageSharp.fluid
    }
    return (
      <Link to={`/${slug}/`} className={styles.item}>
        <div className={styles.header}>
          {featuredImage && (
            <div className={styles.image}>
              <span className={styles.reveal} />
              <Image
                alt="/"
                title="/"
                key="1"
                className={styles.background}
                fluid={featuredImage}
              />
            </div>
          )}
          <div className={styles.meta}>
            <h1>
              {hero.title.map((row, i) => {
                return <span key={i}>{ReactHtmlParser(row)}</span>;
              })}
            </h1>
            <span className={styles.author}>
              {author.name} <b className={styles.vertical}> | </b>
              {date}
            </span>
          </div>
        </div>
      </Link>
    )
  }
}

export default BlogItem
